export default [
  {
    index: 0,
    key: "country",
    labelKey: "country",
    color: "#222222",
    props: {
      url: "https://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-uruguay:country"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'Adaptada por UdeLAR en base a datos Oficiales' },
      { labelKey: 'year', label: 'Ano', value: '2023' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/uruguay/collection_1/download/layers/limite_uruguay.zip'
  },
  {
    index: 1,
    key: "department",
    labelKey: "department",
    color: "#fdbf6f",
    props: {
      url: "https://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-uruguay:department"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'Adaptada por UdeLAR en base a datos Oficiales' },
      { labelKey: 'year', label: 'Ano', value: '2023' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/uruguay/collection_1/download/layers/nivel_politico_1.zip'
  },
  {
    index: 2,
    key: "watershed_level_1",
    labelKey: "watershed_level_1",
    color: "#55f8f8",
    props: {
      url: "https://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-uruguay:watershed_level_1"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'Adaptada por UdeLAR en base a datos Oficiales' },
      { labelKey: 'year', label: 'Ano', value: '2023' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/uruguay/collection_1/download/layers/cuencas_nivel_1.zip'
  },
  {
    index: 3,
    key: "watershed_level_2",
    labelKey: "watershed_level_2",
    color: "#130be3",
    props: {
      url: "https://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-uruguay:watershed_level_2"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'Adaptada por UdeLAR en base a datos Oficiales' },
      { labelKey: 'year', label: 'Ano', value: '2023' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/uruguay/collection_1/download/layers/cuencas_nivel_2.zip'
  },
  {
    index: 4,
    key: "ecoregions",
    labelKey: "ecoregions",
    color: "#E000FF",
    props: {
      url: "https://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-uruguay:ecoregions"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'Adaptada por UdeLAR en base a datos Oficiales' },
      { labelKey: 'year', label: 'Ano', value: '2023' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/uruguay/collection_1/download/layers/regiones_uruguay.zip'
  },
  {
    index: 5,
    key: "national_protected_areas",
    labelKey: "national_protected_areas",
    color: "#11AD0F",
    props: {
      url: "https://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-uruguay:national_protected_areas"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'Adaptada por UdeLAR en base a datos Oficiales' },
      { labelKey: 'year', label: 'Ano', value: '2023' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/uruguay/collection_1/download/layers/areas_protegidas.zip'
  }
];
